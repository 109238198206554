<template>
  <div>
    <div>
      <div>
        <form v-on:submit.prevent="" class="form">
          <div class="heap-block">
            <DateSelector label="Дата нач." :val="date_begin" @change="date_begin = $event" />
          </div>
          <div class="heap-block">
            <DateSelector label="Дата кон." :val="date_end" @change="date_end = $event" />
          </div>
          <div class="heap-block"></div>
          <div class="heap-block">
            <div @click.prevent="brew_turn_copy_reset()" class="high pointer">
              <button class="btn" ref="btn">Обновить</button>
            </div>
          </div>
        </form>
      </div>

      <div>
        <form v-on:submit.prevent="" class="form">
          <div class="heap-block">
            <div
              v-if="turnsEmpty && turnsEmpty.length"
              @click.prevent="autoDataGenerate"
              class="high pointer"
            >
              <button class="btn" ref="btn">Сгенерировать</button>
            </div>
          </div>

          <div
            v-if="turnsGenerated && turnsGenerated.amount"
            @click.prevent="autoDataReset"
            class="high pointer"
          >
            <button class="btn" ref="btn">Обнулить</button>
          </div>
        </form>
      </div>

      <div v-if="loading">
        <Loader />
      </div>

      <AutoPeopleList :date_begin="date_begin" :date_end="date_end" @calculate="getData" />

      <br />

      <div v-if="turnsEmptyShow" class="border-round">
        <div v-if="turnsEmpty && turnsEmpty.length">
          <b class="margins1">Свободных смен в периоде</b>
          <br />
          <br />
          <template v-for="(row, i) in turnsEmpty">
            <div class="margins2" :key="i">{{ `${row.nickname}  ${row.amount}` }}</div>
          </template>
        </div>
        <div v-else>Нет cвободных смен в текущем периоде</div>
      </div>

      <br />

      <div v-if="turnsTruncShow" class="border-round">
        <div v-if="turnsTrunc && turnsTrunc.amount">
          <br />
          <b class="margins1">Обнулено смен {{ turnsTrunc.amount }}</b>
          <br />
        </div>
        <div v-else>Нет обнулённых смен в текущем периоде</div>

        <div v-if="turns">
          <b class="margins1">Заполнено смен {{ turns }}</b>
        </div>
        <br />
      </div>
    </div>

    <br />

    <div class="border-round">
      <h4 class="margins1">Смены</h4>
      <template v-for="(p, i) of dataPeople">
        <div class="margins1" :key="i">{{ p.nickname }}, {{ hoursSummary(p.id) }} ч.</div>
        <div :key="i">
          <template v-for="(t, j) of dataTurns">
            <div class="margins2" :key="j" v-if="t.id_people == p.id">{{ turnShow(t) }}</div>
          </template>
        </div>
        <br :key="i" />
      </template>
    </div>

    <br />
    <br />
  </div>
</template>

<script>
/*
Scenario of work:
1) there are TWO types of people: auto and manual table set
2) admin fill planning period with empty turns (slots)
3) admin fill manual table for appropriate people in slots from previous condition
4) algo fill table for "auto" people
*/
import DateSelector from "@/components/DateSelector.vue";
import AutoPeopleList from "@/components/AutoPeopleList.vue";
import Loader from "@/components/Loader.vue";
import { dateFormatJS } from "@/components-js/dateFormat";
import { request } from "@/components-js/requestSrv";
import { dateFormatHuman } from "@/components-js/dateFormat";
import { POINTS_SHORTLY, DAYTIME } from "@/config/settings";
import { datesHalfMonth } from "@/components-js/datesHalfMonth.js";
import { lS } from "@/components-js/localStorage";
const loStorage = new lS();

export default {
  components: { DateSelector, AutoPeopleList, Loader },

  data() {
    return {
      loading: false,
      // @vue-cli removal
      //      spacer: "\u00A0",
      // @vue-cli removal
      date_begin: null,
      date_end: null,
      id_people: 0,
      report: [],
      total: 0,
      x: 1,
      // empty turns stats
      turnsEmpty: null,
      // flag
      turnsEmptyShow: false,
      // truncated turns
      turnsTrunc: null,
      // flag
      turnsTruncShow: false,
      // generated turns amount
      turnsGenerated: null,
      // turns generated
      turns: 0,
      // turns array
      dataTurns: [],
      // passed array
      dataPeople: [],
      // props array
      dataPropsCommon: {},
      // personal exceptions
      dataPropsPersonalDaily: [],
      // personal weekly rules
      dataPropsPersonalWeekly: [],
    };
  },
  // beforeCreate() {},

  async mounted() {
    // set default values for dates
    const res = datesHalfMonth();
    this.date_begin = dateFormatJS(res.date_begin);
    this.date_end = dateFormatJS(res.date_end);

    let tmp = loStorage.getObjectProp(this.$route.path, "date_begin");
    if (tmp) this.date_begin = tmp;

    tmp = loStorage.getObjectProp(this.$route.path, "date_end");
    if (tmp) this.date_end = tmp;

    this.turnsEmptyShow = true;

    // empty turns by point statistics
    await this.autoEmptyByPointStats();

    // amount of generated turns by algo
    await this.autoGeneratedAmount();
  },

  methods: {
    hoursSummary(id_people) {
      let res = 0;
      for (const i in this.dataTurns)
        if (this.dataTurns[i].id_people == id_people)
          res += this.dataTurns[i].hour_end - this.dataTurns[i].hour_begin + 1;
      return res;
    },

    turnShow(t) {
      return (
        POINTS_SHORTLY[t.id_point] +
        "\u00A0" +
        (t.turn_order ? "\u00A0\u00A0\u00A0\u00A0" : "\u{2615}") +
        "\u00A0" +
        dateFormatHuman(t.turn_date, "w") +
        ", " +
        DAYTIME[t.p_daytime] +
        "\u00A0" +
        t.hour_begin +
        ".." +
        (t.hour_end + 1 == 24 ? 0 : t.hour_end + 1)
      );
    },

    dataPeopleReset() {
      for (const p of this.dataPeople) {
        p.turns_in_a_day = 0;
        p.turns_in_row = parseInt(Math.random() * 5); // ALGO CORRECTIONS
        p.days_off_in_row = 0;
        p.hours_summary = 0;
      }
    },

    async brew_turn_copy_reset() {
      // refill BREW_TURN_COPY
      console.log("BREW_TURN_AUTO_COPY reset begin");
      const res = await request("/api/auto/resetcopy", "POST", {
        date_begin: this.date_begin,
        date_end: this.date_end,
      });
      console.log("BREW_TURN_AUTO_COPY reset end");
      console.log("brew_turn_copy_reset duration", res.duration);

      this.autoEmptyByPointStats();
    },

    getData: function (peopleArray, propsObject, dailyExceptionsArray, weeklyRulesArray) {
      this.dataPeople = peopleArray;
      this.dataPropsCommon = propsObject;
      this.dataPropsPersonalDaily = dailyExceptionsArray;
      this.dataPropsPersonalWeekly = weeklyRulesArray;
      // console.log(
      //   "This from PARENT component",
      //   this.dataPeople,
      //   this.dataPropsCommon,
      //   this.dataPropsPersonalDaily,
      //   this.dataPropsPersonalWeekly
      // );
    },

    async autoGeneratedAmount() {
      // get amount of generated turns by AUTO algo in selected period
      this.turnsGenerated = await request("/api/auto/genturnsamount", "POST", {
        date_begin: this.date_begin,
        date_end: this.date_end,
      });
    },

    async autoEmptyByPointStats() {
      // show amount of empty turns in selected period
      this.turnsEmpty = await request(
        "/api/auto/emptybypoints",
        "POST",
        {
          date_begin: this.date_begin,
          date_end: this.date_end,
        },
        this.$route.path
      );
    },

    async autoDataReset() {
      this.loading = true;
      this.dataPeopleReset();

      console.log("BREW_TURN_AUTO reset begin");
      // DB request
      this.turnsTrunc = await request("/api/auto/reset", "POST", {
        date_begin: this.date_begin,
        date_end: this.date_end,
      });
      console.log("BREW_TURN_AUTO reset end");

      // check for hide reset button
      await this.autoGeneratedAmount();
      this.loading = false;
      this.turnsTruncShow = true;
    },

    //
    // ------------------------------------------------------------------------------------------
    //

    async autoDataGenerate() {
      await this.autoDataReset();
      console.log("-=TURNS FILL=-");
      this.loading = true;

      // clear previous data if need
      // if (this.turnsGenerated && this.turnsGenerated.amount) await this.autoDataReset();

      this.dataTurns = await request("/api/auto/getturnsfree", "POST", {
        date_begin: this.date_begin,
        date_end: this.date_end,
      });
      // convert Mysql JSON string to array ("low" or ["middle", "low"])
      for (const t of this.dataTurns)
        t.p_skill = t.p_skill
          .replaceAll("[", "")
          .replaceAll("]", "")
          .replaceAll('"', "")
          .replaceAll(" ", "")
          .split(",");
      // this.msg = `Cмен на заполнение ${this.dataTurns.length}`;

      // // common props MIXED (default and personal values)
      // let dataPropsCommonAll = await request("/api/auto/getpropscommon", "POST");
      // // common properties DEFAULT ONLY
      // this.msg += "\n\nОбщие условия, шаблон:";
      // const dataPropsCommon = {};
      // for (const P of dataPropsCommonAll) {
      //   if (P.id_user == 0) {
      //     for (const j in P.props) {
      //       dataPropsCommon[j] = parseInt(P.props[j]);
      //       this.msg += `\n ${j}:${dataPropsCommon[j]}`;
      //     }
      //   }
      // }

      // // некоторых людей не расписывать
      // const dataPeople = await request("/api/auto/getpeopleforturns", "POST", {
      //   status: "work",
      //   exclude_people: "3,2,5,17,23",
      //   //include_people: "22,17,20,10,32,9,29,12,35",
      //   include_people: null,
      //   include_job: "1,2,3,4",
      // });

      // this.msg += `\n итого ${dataPeople.length}`;

      // create counters for common properties by copy their names and set zero values
      // for (const i in dataPeople) {
      //   for (const j in dataPropsCommon) dataPeople[i][j] = 0;
      // }

      // this.msg += "\n\nИндивидуальные условия:";
      // const dataPropsPersonalDaily = await request("/api/auto/getpropspersonaldaily", "POST", {
      //   status: "work",
      // });
      // for (const pp of dataPropsPersonalDaily) {
      //   this.msg += `\n ${pp.id}`;
      //   for (const cp in pp.props) this.msg += ` ${cp}:${pp.props[cp]}`;
      // }
      // this.msg += `\n итого ${dataPropsPersonalDaily.length}`;

      // this.msg += "\n\nНедельные условия:";
      // const dataPropsPersonalWeekly = await request(
      //   "/api/auto/getpropspersonalweekly",
      //   "POST",
      //   {
      //     status: "work",
      //   }
      // );
      // for (const pp of dataPropsPersonalWeekly) {
      //   this.msg += `\n ${pp.id}`;
      //   for (const cp in pp.props) this.msg += ` ${cp}:${pp.props[cp]}`;
      // }
      // this.msg += `\n итого ${dataPropsPersonalWeekly.length}`;

      // this.msg += "\n\n";

      shuffle(this.dataPeople);

      console.log("смены с признаками", this.dataTurns);
      console.log("люди с обнулёнными счётчиками", this.dataPeople);
      console.log("общие условия людей", this.dataPropsCommon);
      console.log("индивидуальные условия людей", this.dataPropsPersonalDaily);
      console.log("недельные условия людей", this.dataPropsPersonalWeekly);

      // if (this.dataPeople.length) {
      //   console.log("STOP");
      //   return;
      // }

      this.turns = 0;
      // cycle through selected people
      for (let j = 0; j <= 1; j++) {
        for (const P of this.dataPeople) {
          // at first iteration select people by option
          if (j == 0) {
            let ff = true;
            // those, who with point set
            for (const wp of this.dataPropsPersonalWeekly)
              if (wp.id_people == P.id && wp.props.p_point) {
                console.log(`${P.id} with point set`);
                ff = false;
                break;
              }
            if (ff) continue;
          } else {
            // skip in all next iterations
            let ff = false;
            // those, who with point set
            for (const wp of this.dataPropsPersonalWeekly)
              if (wp.id_people == P.id && wp.props.p_point) {
                console.log(`${P.id} skip`);
                ff = true;
                break;
              }
            if (ff) continue;
          }

          let dateCurrent = null,
            datePrev = null,
            daytimePrev = null;

          let res = {
            p_date: true,
            p_point: true,
            p_skill: true,
            p_weekday: true,
            p_daytime: true,
            p_status: true,
            weekCheck: false,
            hours_summary: false,
            turns_in_row: false,
            days_off_in_row: false,
          };

          // through all turns --------------------------------------
          for (const T of this.dataTurns) {
            if (T.status != "free") continue;

            // experimental condition ALGO CORRECTION
            if (T.turn_order != 0 && T.turn_order != 2) continue;

            // id date changed
            if (dateCurrent != T.turn_date) {
              // обнулить кол-во назначенных смен в сутки
              P.turns_in_a_day = 0;
              // запомнить текущую дату
              dateCurrent = T.turn_date;
            }

            // datePrev NULL value exception
            if (datePrev == null) datePrev = dateCurrent;

            /////////////////////////////////////////////////////////////////////////////////////
            // check turn for SUMMARY props
            //
            // still in current date
            if (dateCurrent == T.turn_date) {
              // check max turns a day
              if (P.turns_in_a_day == P.turns_in_a_day_max) continue;
            }

            // max hours in a period ?
            res.hours_summary = false;
            if (P.hours_summary + T.hour_end - T.hour_begin + 1 <= P.hours_summary_max)
              res.hours_summary = true;

            // max turns in a row ?
            res.turns_in_row = false;
            if (P.turns_in_row >= P.turns_in_row_max) res.turns_in_row = true;

            // min days off in a row ?
            res.days_off_in_row = false;
            if (P.days_off_in_row > P.days_off_in_row_min) res.days_off_in_row = true;

            // check level
            // if boristo level not ehough high - skip turn
            if (checkExc(P.p_skill, T.p_skill) != 1) {
              // console.log("skill fault", P.id, T);
              continue;
            }
            //
            // check turn for SUMMARY props
            //\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

            //

            /////////////////////////////////////////////////////////////////////////////////////
            // check for PERSONAL DAILY props (exceptions)
            //
            // "date" : "2022-03-16" date of exception
            // "status" : "true" | "false", true - use date for turn, false - don't
            // "point" : "1" | "!1" | ["1","2"] | ["!1","!2"]
            // "daytime" : "morinig" | "evening" | "!morning"

            // 0 - neutral, 1 - approve, -1 - reject
            let excStatus = 0;

            for (const exc of this.dataPropsPersonalDaily) {
              // select props for appropriate boristo
              //  DATABASE QUERY CHANGED !!!!
              if (exc.id_user != P.id) continue;
              // !!! if (exc.id != P.id) continue;

              // exceptions set by date
              if (exc.props.date != T.turn_date) continue;

              // disabled by status
              if (exc.props.status == "false") {
                excStatus = -1;
                break;
              }

              // ONE string only for each date

              // point
              excStatus = checkExc(T.id_point, exc.props.point);
              if (excStatus == -1) break;

              // daytime
              excStatus = checkExc(T.p_daytime, exc.props.daytime);
            }

            if (excStatus == -1) {
              // console.log("reject by exception", P.id, T);
              continue;
            }

            if (excStatus == 1) {
              // console.log("selected by exception", P.id, T);
            }

            if (excStatus == 0) {
              // console.log(".");
            }
            //
            // check for PERSONAL DAILY props (exceptions)
            //\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

            /////////////////////////////////////////////////////////////////////////////////////
            // check for WEEK props
            //
            // week record format:
            //
            // weekday: ["Sunday"] | ["!Sunday", "!Monday"] | ["Sunday", "Monday"] | empty
            // status: "false" | empty
            // point: [1] | [1,2]
            // daytime: ["evening"]
            //
            //
            // only if NOT selected by exception
            if (!(excStatus == 1)) {
              res.weekCheck = true;
              for (const cond of this.dataPropsPersonalWeekly) {
                // select props for appropriate boristo
                if (cond.id_people != P.id) continue;

                // select props for appropriate weekday
                // if (cond.props.date != T.turn_date) continue;

                // ONE string for each date
                // through all checks

                // weekday
                res.p_weekday = true;
                res.p_weekday = checkProp(T.p_weekday, cond.props.p_weekday);
                // if weekday is set and differ from current
                if (!res.p_weekday) continue;

                // status
                res.p_status = true;
                if (cond.props.p_status == "false") {
                  res.weekCheck = false;
                  break;
                }

                // point
                res.p_point = true;
                res.p_point = checkProp(T.id_point, cond.props.p_point);

                // daytime
                res.p_daytime = true;
                res.p_daytime = checkProp(T.p_daytime, cond.props.p_daytime);

                res.weekCheck = res.p_weekday && res.p_status && res.p_point & res.p_daytime;
                // хотя бы одно условие не выполнилось
                if (!res.weekCheck) break;
              }
            }

            if (!res.weekCheck) {
              // console.log("reject by weekProps", P.id, T);
              continue;
            }
            //\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

            // console.log("accepted:", P.id, T);

            // по итогам проверки смены

            if (!res.hours_summary) continue; // набрано часов в ПП

            if (res.turns_in_row) {
              // предел смен подряд
              // console.log("нужен выходной");
              // если мало выходных, то далее
              if (dateDiff(datePrev, T.turn_date) <= P.days_off_in_row_min) continue;
              else {
                // сбросить счётчик
                P.turns_in_row = 0;
              }
            }

            // проверка если вчера вечер, то сегодня не утро
            if (
              daytimePrev &&
              dateDiff(datePrev, T.turn_date) == 1 &&
              daytimePrev != T.p_daytime
            )
              continue;

            // назначение смены
            daytimePrev = T.p_daytime;
            T.status = "closed";
            T.id_people = P.id;
            T.id_job = P.id_job;
            T.turn_rate = P.job_rate;
            this.turns++;
            // назначение смены
            P.turns_in_row++; // смен подряд ++
            // если дата новой назначаемой смены отличается от даты предыдущей смены
            if (datePrev != T.turn_date) {
              // если дата отличестся на 1 или меньше
              if (dateDiff(datePrev, T.turn_date) <= 1) {
                // выходных подряд обнулить
                P.days_off_in_row = 0;
              }
              // если дата отличестся более, чем на 1
              if (dateDiff(datePrev, T.turn_date) > 1) {
                // смен подряд обнулить
                P.turns_in_row = 1;
                // выходных подряд увеличить
                P.days_off_in_row = dateDiff(datePrev, T.turn_date);
              }
            }

            datePrev = T.turn_date;

            P.hours_summary += T.hour_end - T.hour_begin + 1;
            P.turns_in_a_day++;
            console.log(
              `${P.id}.`,
              P.nickname,
              P.hours_summary,
              T.turn_date,
              T.p_daytime,
              T.p_weekday,
              T.id_point,
              T.turn_order
            );
          }
          // turns (пройти по всем сменам) --------------------------------------
          console.log("-=====================================-");
        }
      }
      // cycle ONE
      console.log("смен назначено", this.turns);

      const res = await dbUpdate(this.dataTurns);
      console.log(
        `Смен перенесено ${res.affectedRows}, время работы алгоритма ${res.duration} сек.`
      );
      this.loading = false;

      // ====================================================================================
      // local functions
      // ====================================================================================
      //      async function dbUpdate() {
      //for (const T of this.dataTurns) {

      async function dbUpdate(turns) {
        return await request("/api/auto/updateautoturn", "POST", turns);
      }

      await this.autoGeneratedAmount();

      // return 1 | 0 | -1
      // 1 if match with MATCH
      // 0 if not match with EXCLUDE
      // -1 if not match with MATCH
      // -1 if match with EXCLUDE
      function checkExc(excVal, excArr) {
        // if no restriction for excVal, then neutral
        if (!excArr) return 0;
        //console.log("+");
        // excArr defined, convert to Array if still not
        //console.log("excArr", excArr);
        if (!(excArr instanceof Array)) excArr = [excArr];
        // check for MATCH or EXCLUDE
        let res = -1;
        for (const x of excArr) {
          // ! found, then exclude
          if (x.indexOf("!") > -1) res = 0;
        }

        // through each array value
        // console.log("excArr", excArr);
        for (const x of excArr) {
          //console.log(`'${x}', '${excVal}'`);
          // value matched, approve
          if (x == excVal) return 1;
          // value matched and ! found, reject
          if (x.indexOf(excVal) > -1 && x.indexOf("!") > -1) return -1;
        }
        return res;
      }

      // return true if prop accept options
      // false if not
      // return 1 | 0 | -1
      // 1 if match with MATCH
      // 0 if not match with EXCLUDE
      // -1 if not match with MATCH
      // -1 if match with EXCLUDE
      function checkProp(propVal, propArr) {
        // if no restriction for propVal, then neutral
        if (!propArr) return true;
        // propArr defined, convert to Array if still not
        //console.log("propArr", propArr);
        if (!(propArr instanceof Array)) propArr = [propArr];
        // check for MATCH or EXCLUDE
        let res = false;
        for (const x of propArr) {
          // ! found, then exclude
          if (x.indexOf("!") > -1) res = true;
        }

        // through each array value
        // console.log("propArr", propArr);
        for (const x of propArr) {
          //console.log(`'${x}', '${propVal}'`);
          // value matched, approve
          if (x == propVal) return true;
          // value matched and ! found, reject
          if (x.indexOf(propVal) > -1 && x.indexOf("!") > -1) return false;
        }
        return res;
      }

      // ---------------------------------------------------------------------------
      // // return list of points for current man
      // function getPoints(id_people) {
      //   const res = [];
      //   for (const el of dataPropsPersonalDaily)
      //     if (el.id == id_people && el.props.p_point)
      //       for (const p of el.props.p_point) res.push(Number.parseInt(p));
      //   return res;
      // }

      // ---------------------------------------------------------------------------
      // return diff between two days
      function dateDiff(date1, date2, unit = "days") {
        if (date1 == null || date2 == null) return 1;
        const d1 = new Date(date1);
        const d2 = new Date(date2);
        let diff = 0;
        if (d2 >= d1) diff = d2 - d1;
        else diff = d1 - d2;
        switch (unit) {
          case "days":
            diff = diff / 1000 / 3600 / 24;
            break;
          default:
            diff = diff / 1000 / 3600 / 24;
        }
        return diff;
      }

      function shuffle(array) {
        for (let i = array.length - 1; i > 0; i--) {
          let j = Math.floor(Math.random() * (i + 1)); // случайный индекс от 0 до i

          // let t = array[i]; array[i] = array[j]; array[j] = t
          [array[i], array[j]] = [array[j], array[i]];
        }
      }
    },
  },
};
</script>
